
import Vue from 'vue';

import HomeBanner from './components/HomeBanner.vue'
import Articulo from './components/Articulo.vue'
import Filtros from './components/Filters.vue'


export default Vue.extend({
  name: 'App',

  components: {
    HomeBanner,
   Articulo,
    Filtros
  },

  data: () => ({

  }),
});
