<template>
    <v-container>
        <v-btn
        fab
        fixed
        bottom
        right
        color=#941E2B
        @click="dialog= true"
        >
        <v-icon color="white">fa-filter</v-icon>
        </v-btn>
        <v-dialog v-model="dialog" scrollable max-width="400px" >
            <v-card>
                <v-card-title>Filtros</v-card-title>
                <v-card-subtitle>Seleccione máximo 3 categorías.</v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                <v-container>

                    <v-checkbox
                    v-for="item in items"
                    :key="item"
                    v-model="model"
                    :label="item"
                    :value="item"
                    />
                </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-col class="text-right">
                    <v-btn  color="black-darken-1" variant="text"  text @click="dialog = false">ACEPTAR</v-btn>
                </v-col>
                </v-card-actions>
            </v-card>
            </v-dialog>
    </v-container>
</template>


<script>
import { EventBus } from "../main"

export default {
    name: 'FiltrosCategorias',

    data: () => ({
        model: [],
        items: [
            'Gastronomía y alimentos',
            'Decoración, Hogar y Jardín',
            'Deportes y actividades al aire libre',
            'Joyas y accesorios',
            'Mascotas',
            'Niñ@s e Infantes',
            'Salud y Belleza',
            'Servicios',
            'Vestuario y Calzado',
            'Otros'
        ],
        state: [],
        dialog: false

    }),

    created() {
        EventBus.$on("dialog", (estado) => {

            this.dialog = estado;
            console.log('estando desde  created', estado)
        });
    },



    watch: {
        model(val) {

            if (val.length > 3) {
                this.$nextTick(() => this.model.pop())
            }
            if (val.length <= 3) {

                EventBus.$emit("filtrosCategoria", this.model);


            }
        },




    },
}
</script>